@font-face {
    font-family: "Ubuntu";
    src: url("../../fonts/Ubuntu-Regular.ttf");
    font-display: swap;
}

@font-face {
    font-family: "Ubuntu-Light";
    src: url("../../fonts/Ubuntu-Light.ttf");
    font-display: swap;
}

.app{
    overflow: hidden;
    position: relative;
    width: 100vw;
    height: auto;
}

.filter_black{
    filter: invert(100%) sepia(0%) saturate(634%) hue-rotate(209deg) brightness(107%) contrast(104%);
}
//.filter_red{
//    filter: brightness(0) saturate(100%) invert(13%) sepia(35%) saturate(7459%) hue-rotate(336deg) brightness(125%) contrast(95%);
//}

//.menu-btn{
//    filter: invert(100%) sepia(0%) saturate(634%) hue-rotate(209deg) brightness(107%) contrast(104%);
//}

.particles-wrapper{
    height: 100%;
    width: 100%;
}

@media only screen and (max-width: 1366px) {
    .app{
        overflow: auto;
        overflow-x: hidden;
    }
}

@media only screen and (max-width: 550px) {
    .page-bckgrnd{
    }
}